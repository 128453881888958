<template>
  <div v-if="isLoaded" class="home-opp">
    <h2 class="opp-title">My Opportunity Updates</h2>
    <div class="opp-bar">
      <ul class="opp-bar-list">
        <li class="opp-bar-button">
          <h4>All({{ statusCounter[0] }})</h4>
        </li>
        <li class="opp-bar-button">
          <h4>Pending({{ statusCounter[1] }})</h4>
        </li>
        <li class="opp-bar-button">
          <h4>Accepted({{ statusCounter[2] }})</h4>
        </li>
        <li class="opp-bar-button">
          <h4>Declined({{ statusCounter[3] }})</h4>
        </li>
      </ul>
      <div class="search-bar opp-search-bar">
        <div>
          <ion-icon class="small-icon" name="search-outline"></ion-icon>
        </div>
        <input
            v-model="search"
            name="search"
            placeholder="Search job title, companies, etc."
            type="text"
        />
      </div>
    </div>
    <div v-if="filteredOppsData.length == 0 && !isSearching" class="default-opp-column">
      <h3>You haven't requested any referrals from your alumni.</h3>
      <button @click="toOpportunities">Start your referral hunting</button>
    </div>

    <div v-if="filteredOppsData.length == 0 && isSearching" class="default-opp-column">
      <h3>Looks like your search has no result...</h3>
      <button @click="toOpportunities">Continue your referral hunting</button>
    </div>

    <ul v-if="filteredOppsData.length > 0" class="opp-column">
      <li
          v-for="(opp, index) in filteredOppsData"
          :key="opp.oppId"
          :class="{ 'last-item': index == filteredOppsData.length - 1 }"
          class="opp-column-item"
          @click="handleClick(opp.id)"
      >
        <div class="opp-item-left">
          <h2>{{ opp.title }}</h2>
          <h4>{{ opp.company }}</h4>
        </div>
        <div class="opp-item-right">
          <div class="opp-item-icon">
            <ion-icon
                v-if="opp.status == 'pending'"
                class="big-icon violet"
                name="timer-outline"
            ></ion-icon>
            <ion-icon
                v-else-if="opp.status == 'accepted'"
                class="big-icon green"
                name="checkmark-circle-outline"
            ></ion-icon>
            <ion-icon
                v-else
                class="big-icon red"
                name="close-circle-outline"
            ></ion-icon>
          </div>
          <div class="opp-item-status">
            <h4 v-if="opp.status == 'pending'">
              You've submitted the referral request.
            </h4>
            <h4 v-else-if="opp.status == 'accepted'">
              <span class="opp-item-status-bold">{{ opp.alumni }}</span> has accepted your request. You may soon receive
              an email from <span class="opp-item-status-bold">{{ opp.company }}</span>.
            </h4>
            <h4 v-else><span class="opp-item-status-bold">{{ opp.alumni }}</span> has declined your request.</h4>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import getMyOpps from "@/composables/Profile/getMyOpps";
import getOpp from "@/composables/Opp/getOpp";
import getUser from "@/composables/getUser";
import getProfile from "@/composables/Profile/getProfile";

import {useRouter} from "vue-router";
import {onMounted, ref, watchEffect} from "vue";

export default {
  emits: ["homeOppLoaded"],
  async setup(props, {emit}) {
    const router = useRouter();
    const {user} = getUser();
    const search = ref(null);
    const noSpecial = ref(null);
    noSpecial.value = /[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/;
    const myOpps = ref(null);
    const oppsData = ref([]);
    const filteredOppsData = ref([]);
    const statusCounter = ref([0, 0, 0, 0]);
    const isLoaded = ref(false);
    const isSearching = ref(false);

    onMounted(async () => {
      await getMyOpps(myOpps, user.value.uid);

      for (let i = 0; i < myOpps.value.length; i++) {
        const tempOpp = ref(null);
        const tempProfile = ref(null);
        await getOpp(tempOpp, myOpps.value[i].oppId);
        await getProfile(tempProfile, tempOpp.value.alumniId);
        oppsData.value.push({
          ...tempOpp.value,
          alumni:
              tempProfile.value.firstName + " " + tempProfile.value.lastName,
          myOppId: myOpps.value[i].oppId,
          status: myOpps.value[i].status,
        });
        if (myOpps.value[i].status == "pending") {
          statusCounter.value[1]++;
        } else if (myOpps.value[i].status == "accepted") {
          statusCounter.value[2]++;
        } else {
          statusCounter.value[3]++;
        }
        statusCounter.value[0]++;
      }
      isLoaded.value = true;
      emit("homeOppLoaded")
    });

    const handleClick = (oppId) => {
      router.push({name: "Junior-OppDetail", params: {id: oppId}});
    };

    const toOpportunities = () => {
      router.push({name: "Junior-Opportunities"});
    };

    watchEffect(() => {
      filteredOppsData.value = oppsData.value;
      isSearching.value = true
      filteredOppsData.value = filteredOppsData.value.filter((opp) => {
        if (search.value == null) {
          return true;
        } else if (noSpecial.value.test(search.value)) {
          // console.log("no specials");
          return true;
        }
        return opp.title.toLowerCase().match(search.value.toLowerCase());
      });
    });

    return {
      isLoaded,
      myOpps,
      oppsData,
      search,
      filteredOppsData,
      statusCounter,
      handleClick,
      toOpportunities,
      isSearching
    };
  },
};
</script>

<style scoped>
.home-opp {
  animation: fadein 1s;
}

.opp-title {
  font-size: var(--header);
  padding: 1.5vh 0vh;
  font-weight: bolder;
}

.opp-bar {
  display: flex;
  flex-wrap: wrap !important;
  width: 100%;
}

.opp-bar-list {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 60%;
}

.opp-bar-button {
  height: 100%;
  display: inline-block;
  font-size: 110%;
  margin-right: 3vh;
  text-align: center;
}

.opp-bar-button h4 {
  margin-top: 1vh;
  font-weight: 400;
  font-size: var(--text);
}

.default-opp-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8vh;
}

.default-opp-column h3 {
  text-align: center;
  vertical-align: middle;
  margin: 8vh 0 4vh 0;
}

.default-opp-column button {
  text-align: center;
  vertical-align: middle;
  padding: 2vh 4vh;
  color: white;
  font-size: 120%;
  font-weight: 500;
  background-color: #cca2d8;
  border-radius: 4vh;
  border: none;
}

.default-opp-column button:hover {
  background-color: #c069d8;
  cursor: pointer;
}

.opp-column {
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 1vh 3vh;
  margin-top: 3vh;
  align-items: center;
  list-style: none;
}

.opp-column-item {
  display: flex;
  border-bottom: 0.5px rgba(0, 0, 0, 0.5) solid;
  cursor: pointer;
}

.last-item {
  border-bottom: none;
}

.opp-item-left {
  flex: 0 1 50%;
  padding: 2vh 0;
}

.opp-item-left h2 {
  line-height: 3vh;
  font-size: var(--header);
  font-weight: 400;
}

.opp-item-left h4 {
  padding-top: 0.5vh;
  font-size: var(--text);
  font-weight: lighter;
  line-height: 2vh;
}

.opp-item-right {
  flex: 1 0 50%;
  display: flex;
  align-items: center;
}

.opp-item-icon {
  flex: 0 1 9%;
  align-items: center;
  padding-right: 2vh;
}

.opp-item-status {
  flex: 1 0 91%;
  font-size: 125%;
}

.opp-item-status h4 {
  width: 100%;
  font-size: var(--text);
  font-weight: 400;
}

.opp-item-status-bold {
  font-weight: 500;
}

@media screen and (max-width: 600px) {
  .opp-item-right {
    flex-wrap: wrap;
  }

  .opp-item-status h4 {
    font-size: 10px;
  }
}

.search-bar {
  background: #FFFFFF;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  border-radius: 10px;
  min-width: 12rem;
  padding: 0 1vh;
  width: 40%;
  position: relative;
  top: -45px;
}

.search-bar div {
  flex: 0 1 10%;
  background: #FFFFFF;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 80%;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

.search-bar input,
.search-bar input:focus {
  display: block;
  flex: 0 0 80%;
  height: 2vh;
  background: #FFFFFF;
  border: none;
  outline: none;
}

@media screen and (max-width: 950px) {
  .search-bar {
    position: static;
  }

  .opp-search-bar {
    margin-top: 20px;
    width: 90%;
  }

  .opp-title {
    padding: 2vh 3vh;
  }

  .opp-bar {
    padding-left: 3vh;
  }

  .opp-bar-list {
    flex-wrap: wrap;
  }
}
</style>
